import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Title, Text, Process, List, Link, Seo } from '../../components';
import Project from '../../components/Layout/Project';
import Password from '../../components/Layout/Password';
import primeHighlight from '../../images/prime-highlight.png';
import stakeholder from '../../images/emoji/stakeholder.png';
import csd from '../../images/emoji/csd.png';
import search from '../../images/emoji/search.png';
import designJam from '../../images/emoji/design-jam.png';
import critique from '../../images/emoji/critique.png';
import usability from '../../images/emoji/usability.png';

const processList = [
  {
    emoji: stakeholder,
    title: 'Alinhamentos com stakeholder',
    description:
      'O modelo de negócio ainda estava em construção, a gente precisava garantir que estavámos alinhados com as decisões estratégicas dessa frente.',
  },
  {
    emoji: csd,
    title: 'Matriz CSD',
    description:
      'No começo do projeto fizemos uma matriz CSD com o time de produto e desenvolvimento. O objetivo foi de alinhar tudo o que a gente sabia e o que a gente precisava saber do ponto de vista de negócio, produto e engenharia.',
  },
  {
    emoji: search,
    title: 'Pesquisa exploratória',
    description:
      'Conhecer as imobiliárias parceiras antes de começar a pensar em tela foi essencial. Entedemos como estavam estruturados, como trabalhavam e quais eram as expectativas com essa parceria.',
  },
  {
    emoji: designJam,
    title: 'Design Jam',
    description: (
      <>
        Com mais insumos na manga, juntamos com o time desenvolvimento e produto
        e juntos fizemos{' '}
        <Link
          as="a"
          size={16}
          href="https://medium.com/quintoandar-design/design-jams-como-projetar-solu%C3%A7%C3%B5es-melhores-colaborativamente-dfb1411a5778"
          rel="noopener noreferrer"
          target="_blank"
        >
          algumas dinâmicas
        </Link>{' '}
        para estruturar o que seria a primeira entrega desse produto.
      </>
    ),
  },
  {
    emoji: critique,
    title: 'Design Critique',
    description: (
      <>
        Depois de traduzir todas as ideias em interface com os componentes do
        Design System, levamos para os designers de outras tribos darem{' '}
        <Link
          as="a"
          size={16}
          href="https://brasil.uxdesign.cc/design-critique-o-segredo-para-seguran%C3%A7a-emocional-de-designers-uxconf-2018-cca461caea04"
          rel="noopener noreferrer"
          target="_blank"
        >
          feedback
        </Link>{' '}
        e também para conhecer mais desse novo contexto.
      </>
    ),
  },
  {
    emoji: usability,
    title: 'Teste de usabilidade',
    description:
      'Durante toda concepção, fizemos testes com as imobiliárias. Essa etapa foi importante para validar desde as palavras que usamos até como esse fluxo todo se adaptava a rotina delas.',
  },
];

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    display: block;
  }
`;

const About = styled.div`
  max-width: 750px;
  margin-right: 40px;

  ${Link} {
    color: #e39b7f;
    font-weight: 400;
  }

  @media (max-width: 900px) {
    max-width: 100%;
    margin-right: 0;
  }
`;

const Challenge = styled.div`
  margin-bottom: 40px;
`;

const Aside = styled.div`
  max-width: 326px;

  ${Link} {
    color: #e39b7f;
    font-weight: 400;
  }

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

const QuintoAndar = () => (
  <Project
    description="Plataforma de gestão de locação de imóveis"
    title="QuintoAndar para imobiliárias"
    image={primeHighlight}
  >
    <Seo title="Plataforma para gestão de locação de imóveis" />
    <Wrapper>
      <About>
        <Challenge>
          <Title as="h3" size={14} weight={700} marginBottom={24} uppercase>
            Desafio
          </Title>
          <Text size={16} weight={400} color="#423131" lineHeight="27px">
            Depois de alguns anos trabalhando no QuintoAndar e acostumada com os
            usuários do ecossistema (inquilinos, proprietários, fotógrafos,
            corretores e vistoriadores) tive o desafio de participar da
            concepção da primeira versão da plataforma para imobiliárias
            parceiras, uma das primeiras iniciativas B2B da empresa.
          </Text>
        </Challenge>
        <Title as="h3" size={14} weight={700} marginBottom={24} uppercase>
          Processos
        </Title>
        {processList.map(({ emoji, title, description }) => (
          <Process
            emoji={emoji}
            title={title}
            description={description}
            key={title}
          />
        ))}
      </About>
      <Aside>
        <Title as="h3" size={14} weight={700} marginBottom={24} uppercase>
          Como Ajudei
        </Title>
        <List.Unordered bullet="-">
          <List.Item>Pesquisa exploratória com usuário</List.Item>
          <List.Item>Workshop com stakeholders</List.Item>
          <List.Item>Visual design</List.Item>
          <List.Item>Prototipação e teste de usabilidade</List.Item>
        </List.Unordered>
        <Title
          as="h3"
          size={14}
          weight={700}
          marginBottom={24}
          marginTop={40}
          uppercase
        >
          Designers do projeto
        </Title>
        <List.Unordered>
          <List.Item>
            <Link
              as="a"
              href="https://www.linkedin.com/in/leandronovaes/"
              rel="noopener noreferrer"
              target="_blank"
              size={16}
              weight={400}
            >
              Leandro N. - Lead Product Designer
            </Link>
          </List.Item>
          <List.Item>Beatriz F. - Product Designer</List.Item>
        </List.Unordered>
        <Title
          as="h3"
          size={14}
          weight={700}
          marginBottom={24}
          marginTop={40}
          uppercase
        >
          Duração
        </Title>
        <Text size={16} weight={400} color="#423131">
          4 meses (de outubro a janeiro 2019)
        </Text>
        <Title
          as="h3"
          size={14}
          weight={700}
          marginBottom={24}
          marginTop={40}
          uppercase
        >
          Aprendizados
        </Title>
        <List.Ordered>
          <List.Item numbered>
            <List.Number>1</List.Number>
            Ir até as imobiliárias foi importante. Vimos de perto os problemas
            que enfrentam, entedemos como se organizam e quais eram as
            expectativas com a parceria.
          </List.Item>
          <List.Item numbered>
            <List.Number>2</List.Number>
            Tivemos que dar um jeito na distância para continuar em contato com
            os usuários. Fizemos muita pesquisa e teste remoto, aprendemos que
            não existe uma única forma ou ferramenta para isso, tudo vai do seu
            contexto.
          </List.Item>
          <List.Item numbered>
            <List.Number>3</List.Number>
            Pesquisa B2B tem desafios próprios, desde o recrutamento com uma
            base menor até a aplicação da pesquisa em si, já que quem topava
            falar com a gente estava no meio de um dia normal de trabalho.
          </List.Item>
          <List.Item numbered>
            <List.Number>4</List.Number>A gente queria aprender como eram as
            estruturas operacionais das imobiliárias para desenhar fluxos
            eficientes, mas encontramos uma variedade enorme de estruturas, o
            desafio era conseguir adaptar nosso produto para essas variações.
          </List.Item>
        </List.Ordered>
      </Aside>
    </Wrapper>
  </Project>
);

QuintoAndar.propTypes = {
  logged: PropTypes.bool,
};

QuintoAndar.defaultProps = {
  logged: false,
};

export default QuintoAndar;
