import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { Header, GlobalStyle, Container, Title, Text } from '..';
import monkey from '../../images/emoji/monkey.png';

const Form = styled.form`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 20px;

  @media (max-width: 900px) {
    top: 57%;
  }
`;

const Img = styled.img`
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  margin-top: 32px;
  height: 120px;
`;

const Input = styled.input(
  ({ error }) => `
    padding: 20px;
    margin-bottom: 10px;
    max-width: 400px;
    width: 100%;
    height: 60px;
    border: 1px solid #acacac;
    background-color: #fff;
    border-radius: 8px;
    font-size: 16px;
    color: #acacac;
    text-align: center;
    font-family: 'Futura';

    ${error ? 'border-color: #BA5151;' : ''}
  `
);

const Button = styled.button`
  background-color: #80cdba;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  height: 60px;

  cursor: pointer;
`;

const Password = () => {
  const [wrongPassword, setWrongPassword] = useState(false);

  const onSubmit = e => {
    if (typeof window !== 'undefined') {
      e.preventDefault();
      const password = 'bioca';
      const {
        target: {
          password: { value: userPassword },
        },
      } = e;

      if (userPassword !== password) {
        return setWrongPassword(true);
      }

      return navigate(
        '/' +
          window.location.pathname
            .split('/')
            .filter(item => item !== '' && item !== 'bia-portfolio')
            .join(),
        {
          state: {
            logged: true,
          },
        }
      );
    }
  };

  return (
    <>
      <Container>
        <Header />
      </Container>
      <GlobalStyle backgroundColor="#f6f6f6" />
      <Form onSubmit={onSubmit}>
        <Img src={monkey} alt="Emoji de macaco tampando os olhos" />
        <Title as="h2" size={28} weight={500} marginBottom={20}>
          Ops, esse conteúdo é sensível
        </Title>
        <Text size={18} color="#ACACAC">
          pra acessar é preciso da senha
        </Text>
        <InputWrapper>
          <Input
            placeholder="Digite a senha"
            type="password"
            name="password"
            error={wrongPassword}
          />
          {wrongPassword && (
            <Text color="#BA5151" size={18}>
              senha errada :(
            </Text>
          )}
        </InputWrapper>
        <Button type="submit">Acessar projeto</Button>
      </Form>
    </>
  );
};

export default Password;
