import React from 'react';
import PropTypes from 'prop-types';

import { Header, GlobalStyle, Footer, Container, Divider, Highlight } from '..';

const Project = ({ children, title, description, image }) => {
  return (
    <>
      <GlobalStyle />
      <Container backgroundColor="#F6F6F6" fluid marginBottom={78}>
        <Container>
          <Header />
          <Highlight title={title} description={description} image={image} />
        </Container>
      </Container>
      <Container>
        <main>{children}</main>
      </Container>
      <Divider />
      <Container>
        <Footer />
      </Container>
    </>
  );
};

Project.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Project;
